import React, { useEffect } from 'react';
import './DiscordMembersSearch.css';
import { MemberSearchResponse, SearchBy, SearchDetailsMember } from '../api';
import { membersApi } from '../api/helpers/members-api';
import { PopupModal } from './PopupModal';

export interface DiscordMembersSearchProps {
    guildId: string;
}

export const DiscordMembersSearch: React.FC<DiscordMembersSearchProps> = (props) => {
    const [searchBy, setSearchByInternal] = React.useState<SearchBy>(SearchBy.DiscordName);
    const [searchText, setSearchTextInternal] = React.useState<string>('');
    const [page, setPageInternal] = React.useState<number>(1);
    const [searchResults, setSearchResults] = React.useState<MemberSearchResponse>();

    const [showModal, setShowModal] = React.useState<boolean>(false);
    const [selectedMember, setSelectedMember] = React.useState<SearchDetailsMember>();

    function search() {
        membersApi.getMembers(props.guildId, searchText, searchBy, undefined, page).then((response) => {
            setSearchResults(response.data);
        });
    }

    function setPage(newPage: number) {
        if (newPage < 1) {
            newPage = 1;
        }
        setPageInternal(newPage);
    }

    function setSearchBy(searchBy: string) {
        setSearchByInternal(searchBy as SearchBy);
        setPageInternal(1);
    }

    function setSearchText(searchText: string) {
        setSearchTextInternal(searchText);
        setPageInternal(1);
    }

    useEffect(() => {
        search();
    }, []);

    useEffect(() => {
        search();
    }, [page]);

    useEffect(() => {
        search();
    }, [searchBy, searchText]);

    function showMemberDetails(id: string) {
        setShowModal(true);
        setSelectedMember(undefined);
        membersApi.getMember(props.guildId, id).then((response) => {
            setSelectedMember(response.data);
        });
    }

    return (
        <div className="discord-members-search">
            <h1>Discord Members Search</h1>
            <p>Search for Discord members</p>

            <div className="top-bar">
                <div className="paging-container">
                    <div className="current-page">Total: {searchResults?.totalCount}</div>
                    <div className="current-page">
                        Page {page} of {searchResults?.totalPages}
                    </div>
                    <div className="page-buttons">
                        <button className="btn btn-primary" onClick={() => setPage(page - 1)} disabled={page === 1}>
                            Previous
                        </button>
                        <button
                            className="btn btn-primary"
                            onClick={() => setPage(page + 1)}
                            disabled={page >= (searchResults?.totalPages ?? 0)}
                        >
                            Next
                        </button>
                    </div>
                </div>

                <div className="search-container">
                    <div className="search-options">
                        <label>Search By:</label>
                        <select
                            className="search-dropdown"
                            onChange={(e) => setSearchBy(e.target.value)}
                            value={searchBy}
                        >
                            <option>DiscordId</option>
                            <option>DiscordName</option>
                            <option>VrcId</option>
                            <option>VrcName</option>
                        </select>
                        <div className="search-bar">
                            <input
                                className="search-input"
                                type="text"
                                placeholder="Search..."
                                onChange={(e) => setSearchText(e.target.value)}
                            />
                            <button className="btn btn-primary" onClick={() => search()}>
                                Search
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="search-results">
                {searchResults?.results.map((member) => (
                    <div className="search-result" onClick={() => showMemberDetails(member.id)} key={member.id}>
                        <div className="avatar">
                            <img src={member.avatar ?? ''} alt="Avatar" />
                        </div>
                        <div className="info">
                            <b>Username</b>
                            <p>{member.username}</p>
                        </div>
                        <div className="info">
                            <b>Discord ID</b>
                            <p>{member.id}</p>
                        </div>
                        <div className="info">
                            <b>Discord Roles</b>
                            <p>{member.discordRoles}</p>
                        </div>
                        <div className="info">
                            <b>VRC-ID</b>
                            <p>{member.vrcId ?? 'None'}</p>
                        </div>
                        <div className="info">
                            <b>VRC-Name</b>
                            <p>{member.vrcName ?? 'None'}</p>
                        </div>
                        <div className="info">
                            <b>Link Last Updated</b>
                            <p>{member.linkLastChanged ? FormatDate(member.linkLastChanged) : 'Never'}</p>
                        </div>
                        <div className="info">
                            <b>Link Count</b>
                            <p>{member.linkCount ?? 'None'}</p>
                        </div>
                        <div className="info">
                            <b>Verified</b>
                            <p>{member.isVerified ? 'Yes' : 'No'}</p>
                        </div>
                        <div className="info">
                            <b>Age Verified</b>
                            <p>{member.isAgeVerified ? 'Yes' : 'No'}</p>
                        </div>
                    </div>
                ))}
            </div>

            <PopupModal show={showModal} onClose={() => setShowModal(false)} title="Member Details">
                <div>
                    <h3>Roles</h3>
                    <div>
                        <ol>{selectedMember?.discordRoles.map((role) => <li>{role}</li>)}</ol>
                    </div>
                </div>
            </PopupModal>
        </div>
    );
};

function FormatDate(date: string) {
    // Date format: 2021-08-31
    return new Date(date).toLocaleString();
}
