import React from 'react';
import { useLocation } from 'react-router-dom';
import { tokenApi } from '../api/helpers/auth-api';

const OAuth: React.FC = () => {
    const location = useLocation();
    const [approved, setApproved] = React.useState<boolean>(false);
    const loginCallback = async () => {
        const code = new URLSearchParams(location.search).get('token');
        if (code) {
            tokenApi.oAuthApprove({ token: code }).then(() => {
                setApproved(true);
            });
        }
    };

    return (
        <div className="oauth">
            {approved && <h1>Application has been granted access to your VRCLinking account</h1>}
            {!approved && (
                <div>
                    <h1>Application wants to access your VRCLinking account</h1>
                    <p>By clicking "Allow", you allow the application to access your VRCLinking account.</p>
                    <button className="btn btn-success" onClick={loginCallback}>
                        Allow
                    </button>
                    <button
                        className="btn btn-danger"
                        onClick={() => {
                            window.location.href = '/';
                        }}
                    >
                        Deny
                    </button>
                </div>
            )}
        </div>
    );
};

export default OAuth;
