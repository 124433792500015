import React, { useEffect } from 'react';
import { useAuth } from './AuthProvider';
import { ScheduledTaskInfo, UserRank } from '../api';
import { adminApi } from '../api/helpers/admin-api';
import './Admin.css';
import { CustomButton } from '../components/CustomButton';

export const Admin: React.FC = () => {
    const { userData } = useAuth();
    const [scheduledTasks, setScheduledTasks] = React.useState<ScheduledTaskInfo[]>([]);
    const [refreshInterval, setRefreshInterval] = React.useState<NodeJS.Timeout>();

    useEffect(() => {
        if (userData.rank !== UserRank.Admin) {
            window.location.href = '/';
        }

        refreshTasks();

        if (refreshInterval) {
            clearInterval(refreshInterval);
        }

        const interval = setInterval(() => {
            refreshTasks();
        }, 1000);
        setRefreshInterval(interval);

        return () => {
            clearInterval(interval);
        };
    }, [userData.rank]);

    function refreshTasks() {
        if (userData.rank !== UserRank.Admin) {
            refreshInterval && clearInterval(refreshInterval);
        }
        adminApi.getScheduledTasks().then((response) => {
            setScheduledTasks(response.data);
        });
    }

    const toggleEnabled = (task: ScheduledTaskInfo) => {
        setScheduledTasks((prevTasks) =>
            prevTasks.map((t) =>
                t.method === task.method && t.api === task.api && t.container === task.container
                    ? { ...t, enabled: !t.enabled }
                    : t
            )
        );
        adminApi
            .toggleTask(task.api, task.container, task.method)
            .then(() => {
                refreshTasks();
            })
            .catch(() => {
                alert('Failed to toggle task state.');
            });
    };

    const formatTimeDiff = (timestamp: string) => {
        const now = new Date();
        const target = new Date(timestamp);
        const diffMs = target.getTime() - now.getTime();

        if (diffMs >= -500 && diffMs <= 500) return 'just now';

        const isPast = diffMs < 0;
        const absDiffMs = Math.abs(diffMs);

        const seconds = Math.floor((absDiffMs / 1000) % 60);
        const minutes = Math.floor((absDiffMs / (1000 * 60)) % 60);
        const hours = Math.floor((absDiffMs / (1000 * 60 * 60)) % 24);
        const days = Math.floor(absDiffMs / (1000 * 60 * 60 * 24));

        let result = '';
        if (days > 0) result = `${days}d ${hours}h ${minutes}m`;
        else if (hours > 0) result = `${hours}h ${minutes}m ${seconds}s`;
        else if (minutes > 0) result = `${minutes}m ${seconds}s`;
        else result = `${seconds}s`;

        return isPast ? `${result} ago` : `in ${result}`;
    };

    const groupedTasks = scheduledTasks.reduce(
        (groups, task) => {
            const key = `${task.api}::${task.container}`;
            if (!groups[key]) {
                groups[key] = [];
            }
            groups[key].push(task);
            return groups;
        },
        {} as Record<string, ScheduledTaskInfo[]>
    );

    return (
        <div className={'admin-page-container'}>
            <h1>Scheduled Tasks</h1>
            <div className={'admin-page'}>
                {Object.entries(groupedTasks).map(([key, tasks]) => {
                    const [apiName, containerName] = key.split('::');
                    return (
                        <div key={key} className={'api-group container-group'}>
                            <h2>{apiName}</h2>
                            <h3>{containerName}</h3>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Method</th>
                                        <th>Next Run</th>
                                        <th>Last Run</th>
                                        <th>Timer</th>
                                        <th>Enabled</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {tasks.map((task) => (
                                        <tr key={task.method}>
                                            <td>{task.method}</td>
                                            <td>{formatTimeDiff(task.nextRun)}</td>
                                            <td>{task.lastRun ? formatTimeDiff(task.lastRun) : 'N/A'}</td>
                                            <td>{task.timer}s</td>
                                            <td>
                                                <input
                                                    type="checkbox"
                                                    checked={task.enabled}
                                                    onChange={() => toggleEnabled(task)}
                                                />
                                            </td>
                                            <td>
                                                <CustomButton
                                                    name={'Run now'}
                                                    className={'btn-success'}
                                                    onClick={() => {
                                                        adminApi
                                                            .runTask(task.api, task.container, task.method)
                                                            .then(() => {
                                                                refreshTasks();
                                                            });
                                                    }}
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
