import { AuthApi, Configuration } from '../api';
import { BASE_PATH } from '../api/base';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { authApi } from '../api/helpers/auth-api';

const LoginCallback: React.FC = () => {
    const location = useLocation();
    const loginCallback = async () => {
        const code = new URLSearchParams(location.search).get('token');
        if (code) {
            await handleAuthCode(code);
        }
    };

    const handleAuthCode = async (code: string) => {
        try {
            await authApi.discordAuth(code);
            window.location.href = '/profile';
        } catch (e) {
            console.error(e);
            window.location.href = '/';
        }
    };

    React.useEffect(() => {
        loginCallback();
    });

    return <div>Logging in... Please wait.</div>;
};

export default LoginCallback;
