import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Index from './pages/Index';
import Profile from './pages/Profile';
import Tos from './pages/Tos';
import PrivacyPolicy from './pages/PrivacyPolicy';
import './App.css';
import ServerSettings from './pages/ServerSettings';
import Login from './pages/Login';
import LoginCallback from './pages/LoginCallback';
import { AuthProvider } from './pages/AuthProvider';
import Logout from './pages/Logout';
import axios from 'axios';
import RequireLogin from './components/RequireLogin';
import { Admin } from './pages/Admin';
import TokenLogin from './pages/TokenLogin';
import OAuth from './pages/OAuth';

axios.defaults.withCredentials = true;

const App: React.FC = () => {
    return (
        <AuthProvider>
            <Router>
                <div className="App">
                    <Header />
                    <Routes>
                        <Route path="/" element={<Index />} />
                        <Route path="/tos" element={<Tos />} />
                        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                        <Route
                            path="/profile"
                            element={
                                <RequireLogin>
                                    <Profile />
                                </RequireLogin>
                            }
                        />
                        <Route
                            path="/server-settings/*"
                            element={
                                <RequireLogin>
                                    <ServerSettings />
                                </RequireLogin>
                            }
                        />
                        <Route path="/discord/login/" element={<Login />} />
                        <Route path="/logout" element={<Logout />} />
                        <Route path="/discord/login/callback" element={<LoginCallback />} />
                        <Route path="/discord_auth" element={<TokenLogin />} />
                        <Route path="/admin" element={<Admin />} />
                        <Route
                            path="/sdk-oauth"
                            element={
                                <RequireLogin>
                                    <OAuth />
                                </RequireLogin>
                            }
                        />
                    </Routes>
                </div>
            </Router>
        </AuthProvider>
    );
};

export default App;
