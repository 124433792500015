import React from 'react';
import { Button } from '@mui/material';
import ButtonItem from './ButtonItem';
import Select from 'react-select';
import './SingleSelectPickerItem.css';
import { darkThemeSelect } from './Styles';

interface SingleSelectPickerItemProps {
    name: string;
    options: SelectOption[];
    selectedOption?: SelectOption;
    onChange: (selectedOption: SelectOption) => void;
    allowClear?: boolean;
}

interface SelectOption {
    value: string;
    label: string;
    color: string;
    position: number;
}

export const SingleSelectPickerItem: React.FC<SingleSelectPickerItemProps> = ({
    name,
    options,
    selectedOption,
    onChange,
    allowClear,
}) => {
    return (
        <div>
            <div className="list-group-item server-toggles-item">
                <h3>{name}</h3>
                <Select
                    isMulti={false}
                    options={options}
                    value={selectedOption}
                    className={'server-toggles-item-select-item'}
                    styles={darkThemeSelect}
                    isClearable={allowClear}
                    onChange={(value) => {
                        onChange(value as SelectOption);
                    }}
                    placeholder={'None Selected'}
                />
            </div>
        </div>
    );
};
