import React from 'react';
import './DiscordSettings.css';
import SelectPickerItem from './SelectPickerItem';
import { guildsApi } from '../api/helpers/guilds-api';
import { GuestUsersPicker } from './GuestUsersPicker';
import { AutoAssignRoles, EncodeRole, Guild } from '../api';
import ButtonItem from './ButtonItem';
import { DiscordMembersSearch } from './DiscordMembersSearch';
import { SingleSelectPickerItem } from './SingleSelectPickerItem';

interface ServerDetailsProps {
    serverId: string;
}

const DiscordSettings: React.FC<ServerDetailsProps> = (server) => {
    const [serverDetails, setServerDetails] = React.useState<Guild>({} as Guild);
    const [isLoading, setIsLoading] = React.useState(true);
    const [isMemberListOpen, setIsMemberListOpen] = React.useState(false);

    React.useEffect(() => {
        setIsLoading(true);
        guildsApi
            .getGuild(server.serverId)
            .then((response) => {
                setServerDetails(response.data);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [server.serverId]);

    function saveAutoAssignRoles(autoAssignRoles: AutoAssignRoles) {
        guildsApi
            .updateGuild(serverDetails.id, {
                autoAssignRoles: {
                    ageVerifiedRoleId: autoAssignRoles.ageVerifiedRoleId ?? undefined,
                    ageVerifiedPlus18RoleId: autoAssignRoles.ageVerifiedPlus18RoleId ?? undefined,
                    linkedRoleId: autoAssignRoles.linkedRoleId ?? undefined,
                },
            })
            .then((response) => {
                setServerDetails({ ...serverDetails });
            })
            .catch((error) => {
                console.error(error);
            });
    }

    function getSelectItemRole(roleId?: string | null) {
        if (!roleId) {
            return undefined;
        }
        const role = serverDetails.roles.find((role) => role.id === roleId);
        if (!role) {
            return undefined;
        }

        return {
            value: role.id,
            label: role.name,
            color: role.color,
            position: role.position,
        };
    }

    return isLoading ? (
        <div className="server-details">Loading..</div>
    ) : !isMemberListOpen ? (
        <div className="server-details">
            <div className="icon">
                <img src={serverDetails.icon!} alt="Server Icon" />
            </div>
            <h1>{serverDetails.name}</h1>
            <div className="member-info">
                <div>
                    <p>Members: {serverDetails.members}</p>
                </div>
                <div>
                    <p>Linked Members: {serverDetails.linkedMembers}</p>
                </div>
            </div>
            <div className="data-urls">
                <button className="btn btn-primary">Data URL (Untrusted URLs)</button>
                <button className="btn btn-primary">Data URL (Trusted URL)</button>
            </div>

            <div className={'setting-title'}>
                <h1>Settings</h1>
            </div>

            <div className="server-toggles list-group">
                <ButtonItem
                    name={'Member List'}
                    buttonName={'Open'}
                    onClick={() => {
                        setIsMemberListOpen(true);
                    }}
                />

                <SelectPickerItem
                    name={'Select Roles To Encode'}
                    modalTitle={'Select Roles'}
                    buttonName={'Select Roles'}
                    options={
                        // @ts-ignore
                        serverDetails.roles.map((role) => {
                            return {
                                value: role.id,
                                label: role.name,
                                color: role.color,
                                position: role.position,
                            };
                        })
                    }
                    selectedOptions={serverDetails.encRoleList.map((role) => {
                        return {
                            value: role.id,
                            label: role.name,
                            color: role.color,
                            position: role.position,
                        };
                    })}
                    // @ts-ignore
                    onChange={(value) => {
                        serverDetails.encRoleList = value.map((role) => {
                            return {
                                id: role.value,
                                name: role.label,
                                color: role.color,
                                position: role.position,
                            };
                        });
                        guildsApi.updateGuild(serverDetails.id, {
                            encRoleList: serverDetails.encRoleList.map((role: { id: string }) => {
                                return role.id;
                            }),
                        });
                        setServerDetails({ ...serverDetails });
                    }}
                />
                <SelectPickerItem
                    name={'Select Roles To Always Encode'}
                    modalTitle={'Select Roles'}
                    buttonName={'Select Roles'}
                    options={
                        // @ts-ignore
                        serverDetails.roles.map((role) => {
                            return {
                                value: role.id,
                                label: role.name,
                                color: role.color,
                                position: role.position,
                            };
                        })
                    }
                    selectedOptions={
                        // @ts-ignore
                        serverDetails.alwaysEncRoles.map((role) => {
                            return {
                                value: role.id,
                                label: role.name,
                                color: role.color,
                                position: role.position,
                            };
                        })
                    }
                    // @ts-ignore
                    onChange={(value) => {
                        serverDetails.alwaysEncRoles = value.map((role) => {
                            return {
                                id: role.value,
                                name: role.label,
                                color: role.color,
                                position: role.position,
                            };
                        });
                        guildsApi.updateGuild(serverDetails.id, {
                            alwaysEncRoles: serverDetails.alwaysEncRoles.map((role: { id: string }) => {
                                return role.id;
                            }),
                        });
                        setServerDetails({ ...serverDetails });
                    }}
                />
                <SingleSelectPickerItem
                    name={'Age Verified Role'}
                    options={serverDetails.roles
                        .filter((role) => role.name !== '@everyone')
                        .map((role) => {
                            return {
                                value: role.id,
                                label: role.name,
                                color: role.color,
                                position: role.position,
                            };
                        })}
                    selectedOption={getSelectItemRole(serverDetails.autoAssignRoles.ageVerifiedRoleId)}
                    onChange={(selectedOption) => {
                        if (selectedOption) {
                            serverDetails.autoAssignRoles.ageVerifiedRoleId = selectedOption.value;
                            saveAutoAssignRoles(serverDetails.autoAssignRoles);
                        } else {
                            serverDetails.autoAssignRoles.ageVerifiedRoleId = null;
                            saveAutoAssignRoles(serverDetails.autoAssignRoles);
                        }
                    }}
                    allowClear={true}
                />
                <SingleSelectPickerItem
                    name={'18+ Verified Role'}
                    options={serverDetails.roles
                        .filter((role) => role.name !== '@everyone')
                        .map((role) => {
                            return {
                                value: role.id,
                                label: role.name,
                                color: role.color,
                                position: role.position,
                            };
                        })}
                    selectedOption={getSelectItemRole(serverDetails.autoAssignRoles.ageVerifiedPlus18RoleId)}
                    onChange={(selectedOption) => {
                        if (selectedOption) {
                            serverDetails.autoAssignRoles.ageVerifiedPlus18RoleId = selectedOption.value;
                            saveAutoAssignRoles(serverDetails.autoAssignRoles);
                        } else {
                            serverDetails.autoAssignRoles.ageVerifiedPlus18RoleId = null;
                            saveAutoAssignRoles(serverDetails.autoAssignRoles);
                        }
                    }}
                    allowClear={true}
                />
                <SingleSelectPickerItem
                    name={'Linked Users Role'}
                    options={serverDetails.roles
                        .filter((role) => role.name !== '@everyone')
                        .map((role) => {
                            return {
                                value: role.id,
                                label: role.name,
                                color: role.color,
                                position: role.position,
                            };
                        })}
                    selectedOption={getSelectItemRole(serverDetails.autoAssignRoles.linkedRoleId)}
                    onChange={(selectedOption) => {
                        if (selectedOption) {
                            serverDetails.autoAssignRoles.linkedRoleId = selectedOption.value;
                            saveAutoAssignRoles(serverDetails.autoAssignRoles);
                        } else {
                            serverDetails.autoAssignRoles.linkedRoleId = null;
                            saveAutoAssignRoles(serverDetails.autoAssignRoles);
                        }
                    }}
                    allowClear={true}
                />
                <GuestUsersPicker guild={serverDetails} />
            </div>
        </div>
    ) : (
        <DiscordMembersSearch guildId={server.serverId} />
    );
};

export default DiscordSettings;
