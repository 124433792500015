import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js'; // Ensure JavaScript is loaded
import './Header.css';
import { useAuth } from '../pages/AuthProvider';

const Header: React.FC = () => {
    const { isLoggedIn, userData } = useAuth();
    const location = useLocation();

    return (
        <header className="navbar navbar-expand-lg navbar-dark bg-dark">
            <div className="container header-container">
                <Link className="navbar-brand d-flex align-items-center" to="/">
                    <img src="/image/logo.png" alt="VRCLinking" className="logo-img" />
                    <span className="ms-2">VRCLinking</span>
                </Link>
                <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <Link className={`nav-link ${location.pathname === '/' ? 'active' : ''}`} to="/">
                                Home
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link
                                className={`nav-link ${location.pathname === '/privacy-policy' ? 'active' : ''}`}
                                to="/privacy-policy"
                            >
                                Privacy Policy
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link className={`nav-link ${location.pathname === '/tos' ? 'active' : ''}`} to="/tos">
                                Terms of Service
                            </Link>
                        </li>
                        {isLoggedIn && (
                            <li className="nav-item">
                                <Link
                                    className={`nav-link ${location.pathname.includes('/profile') ? 'active' : ''}`}
                                    to="/profile"
                                >
                                    Profile
                                </Link>
                            </li>
                        )}
                        {isLoggedIn && (
                            <li className="nav-item">
                                <Link
                                    className={`nav-link ${location.pathname.includes('/server-settings') ? 'active' : ''}`}
                                    to="/server-settings"
                                >
                                    Server Settings
                                </Link>
                            </li>
                        )}

                        {userData.rank === 'Admin' && (
                            <li className="nav-item">
                                <Link
                                    className={`nav-link ${location.pathname === '/admin' ? 'active' : ''}`}
                                    to="/admin"
                                >
                                    Admin
                                </Link>
                            </li>
                        )}
                    </ul>
                    <div className="user-div d-flex align-items-center">
                        {isLoggedIn ? (
                            <>
                                {userData.rank === 'Admin' && (
                                    <span className="badge bg-danger mx-2">{userData.rank}</span>
                                )}
                                <img
                                    className="rounded-circle"
                                    src={userData.avatar ?? ''}
                                    alt={userData.username?.toString()}
                                    style={{ width: '3em', height: '3em' }}
                                />
                                <span className="mx-2">@{userData.username}</span>

                                <Link to="/logout" className="btn btn-danger ms-2">
                                    Logout
                                </Link>
                            </>
                        ) : (
                            <Link to="/discord/login" className="btn btn-primary">
                                Login through Discord
                            </Link>
                        )}
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;
