// src/pages/Index.tsx
import React from 'react';
import './MainContent.css';

const Index: React.FC = () => {
    return (
        <main className="index-main">
            <header className="header">
                <h1 style={{ color: '#1fd1ed' }}>VRCLinking</h1>
            </header>

            <section className="hero text-center">
                <h2>Seamless Linking and Syncing for VRChat</h2>
                <p>
                    Discord Bot for VRChat Creators to allow their users to link their VRChat account to their Discord
                    account.
                </p>
                <div className="hero-buttons">
                    <a href="https://discord.gg/P8rNe9HXVZ" className="cta-button">
                        Support Discord
                    </a>
                    <a
                        href="https://discord.com/api/oauth2/authorize?client_id=938573401201721425&permissions=2147600448&scope=bot%20applications.commands"
                        className="cta-button"
                    >
                        Invite to Server
                    </a>
                </div>
            </section>

            <section className="features">
                <div className="feature">
                    <h3>Group Management</h3>
                    <p>
                        Manage your VRChat group with ease. Sync your Discord roles with your VRChat group, invite users
                        to your group, and more.
                    </p>
                </div>
                <div className="feature">
                    <h3>Linking</h3>
                    <p>
                        Let your users link their VRChat account to their Discord account. Verify their ownership and
                        age.
                    </p>
                </div>
                <div className="feature">
                    <h3>World Management</h3>
                    <p>
                        Manage supporter boards and perks in your worlds. Sync your Discord roles with your VRChat
                        worlds via String Loading.
                    </p>
                </div>
                <div className="feature">
                    <h3>Role assignments</h3>
                    <p>Assign roles to your users based on linking status or age verification.</p>
                </div>
            </section>

            <footer className="footer">
                <p>
                    &copy; 2025 VRCLinking. All Rights Reserved. |{' '}
                    <a href="/privacy" className="footer-link">
                        Privacy Policy
                    </a>
                </p>
            </footer>
        </main>
    );
};

export default Index;
